<template>
  <div>
    <div class="row items-center q-py-sm q-px-md">
      <q-input
          v-if="isAdministrator"
          :model-value="idFilter"
          class="col"
          type="text"
          standout="bg-teal text-white"
          dense
          :label="$t('Id')"
          style="width: 20%"
          @update:model-value="handleInput"
      />
    </div>

    <div class="border rounded q-ma-sm">
      <div class="text-dark q-px-sm row items-stretch">
        <div
            class="col text-h6 text-center text-dark rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
        >
          {{ $t('Please select queue to start work!') }}
        </div>
      </div>
    </div>
    <div class="q-mx-sm">
      <div
          v-for="(item, i) in queues"
          :key="i"
          class="q-mb-sm"
      >
        <labelling-queue
            :data="item"
            :rawData="item"
            @click="handleClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import LabellingQueue from '../../components/labelling/labelling-queue.vue'

export default {
  name: 'OrderAssembling',
  components: {
    LabellingQueue,
  },
  data () {
    return {
      queues: [],
      isLoading: false,
      idFilter: null
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'queue',
      'isAdministrator'
    ]),
  },
  mounted () {
    this.handleLoadPreprcessingQueues()
  },
  methods: {
    ...mapActions([
      'loadStoragePlaces',
    ]),
    ...mapMutations([
      'setPrinter',
      'addErrorNotification',
      'setQueue'
    ]),
    handleLoadPreprcessingQueues () {
      const query = {}
      query.filter = [{type: 'eq', field: 'state' , value: 'active'}]
      if (this.idFilter) {
        query.filter.push({ type: 'eq', field: 'id', value: this.idFilter })
      }

      return this.$service.preprocessingQueue.getAll(query)
          .then(({ items }) => {
            this.queues = items

            if (items.length === 1) {
              this.handleClick(items[0])
            }
          })
    },
    handleStartLoading () {
      this.isLoading = true
    },
    handleStopLoading () {
      this.isLoading = false
    },
    handleBlock () {
      this.isFocused = true
    },
    handleUnblock () {
      this.isFocused = false
    },
    handleClick (data) {
      this.setQueue(data)
      this.$router.push(`/workstation/labelling/regular/${data.id}`)
    },
    handleInput (data) {
      this.idFilter = data
      this.handleLoadPreprcessingQueues()
    }
  }
}
</script>
