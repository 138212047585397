<template>
  <div
      :class="`card border-0 rounded picking-item picking-warning shadow text-dark`"
      @click="handleClick"
  >
    <div
        class="row justify-between full-width border-bottom q-pb-xs q-mb-xs q-pt-sm q-px-sm"
        :style="isOpen ? 'opacity: .4;' : ''"
    >
      <div>
        <strong>{{ $t('ID') + ': ' + data.id }}</strong>
      </div>
    </div>

    <div
      class="text-center q-pb-sm"
      :style="isOpen ? 'opacity: .4;' : ''"
    >
      {{ data.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LabellingQueue',
  emits: ['click'],
  props: {
    data: {
      type: Object,
      default () {
        return {
          options: []
        }
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
  },
  methods: {
    handleClick () {
      const data = {
        ...this.rawData
      }

      this.$emit('click', data)
    }
  }
}
</script>

<style>

.picking-warning {
    background-color: #ffff7f !important;
}
</style>
